export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Porsche-NA',
  hostName: 'analytics-staging.porschedealerdigitalprogram.com',
  environmentName: 'staging',
  appName: 'Porsche Analytics',
  applicationInsights: {
    instrumentationKey: '72ef1b3f-a524-4dcf-84d7-d80d9ac64287'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#34383c', '#c51230', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://porschena-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://porschena-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://porscheus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/porsche-na/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: []
};
